import { ContentChangeAlertAPI } from '@analyzer/client';
import { Box, Button, Typography } from '@mui/material';
import * as Diff2Html from 'diff2html';
import 'diff2html/bundles/css/diff2html.min.css';
import { useState } from 'react';
import { useService } from '../../providers/ServiceProvider.js';
import SummarizedLink from '../url.js';

function RawDifferencesComponent({ alert }: { alert: ContentChangeAlertAPI }) {
  const [diffHtml, setDiffHtml] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const client = useService();

  const fetchDifferences = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // Submit job for differences
      const job = await client.submitResponseBodyDiff(alert.request0.response.id, alert.request1.response.id);

      // Wait for the job to complete with a timeout safety
      await client.jobWaitForStatus(job.id, 'completed');

      // Fetch the differences
      const contents = await client.readResponseBodyDiff(
        alert.request0.response.id,
        alert.request1.response.id
      );

      // Generate HTML for differences
      const code = Diff2Html.html(contents, {
        outputFormat: 'line-by-line',
        drawFileList: false
      });

      setDiffHtml(code);
    } catch (err) {
      setError('Failed to fetch differences. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return <Typography>Loading differences...</Typography>;
    }

    if (error) {
      return <Typography color="error">{error}</Typography>;
    }

    if (diffHtml) {
      return <div dangerouslySetInnerHTML={{ __html: diffHtml }} />;
    }

    return (
      <Button onClick={fetchDifferences} disabled={isLoading}>
        Show Differences
      </Button>
    );
  };

  return (
    <>
      <Typography variant="subtitle2">Content Difference</Typography>
      {renderContent()}
    </>
  );
}

export function ContentChangeAlertComponent(alert: ContentChangeAlertAPI) {
  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="subtitle2">Description</Typography>
        <Typography variant="body2">
          A Content Change Alert signals significant modifications to a website's resource content, which may
          indicate updates or potential security concerns.
        </Typography>
      </Box>

      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="subtitle2">Impact</Typography>
        <Typography variant="body2">The impact of this change is assessed as {alert.severity}.</Typography>
      </Box>

      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="subtitle2">Details</Typography>
        <Typography variant="body2">
          The resource at <SummarizedLink url={alert.request0.request.url} title="this location" /> (origin{' '}
          <SummarizedLink
            url={new URL(alert.request0.request.url).origin}
            title={new URL(alert.request0.request.url).origin}
          />
          ) has been altered. Detailed below is a 'diff' showcasing the differences between the prior and
          current content versions, enabling specific modifications to be identified.
        </Typography>
        <Typography variant="body2">
          The diff operation compares the following URLs from application snapshots:
        </Typography>
        <Typography variant="body2">
          <SummarizedLink url={alert.request0.request.url} />
        </Typography>
        <Typography variant="body2">
          <SummarizedLink url={alert.request1.request.url} />
        </Typography>
        <Typography variant="body2">
          {alert.stable
            ? 'This resource is typically stable; this change marks the first deviation following a sequence of consistent snapshots'
            : 'This resource is frequently updated, suggesting it may be a dynamic endpoint or involve session-specific pages that change upon each visit.'}
          .
        </Typography>
        <RawDifferencesComponent alert={alert} />
      </Box>
    </>
  );
}
